import React, { useState, useEffect } from "react";
import Nav from "./nav";
import logo from "../assets/img/logo.png";
const Header = () => {
  const [isVisible, setIsVisible] = useState(true);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <header
        id="header"
        className={`fixed-top d-flex align-items-center ${
          isVisible ? "header-container" : "header-scrolled"
        }`}
      >
        <div className="container">
          <div className="header-container d-flex align-items-center justify-content-between">
            <div className="logo">
              <a to="index.html">
                <img src={logo} alt="" className="img-fluid" />
              </a>
            </div>
            <Nav />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
