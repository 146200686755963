import React from "react";

const Nav = () => {
return (
<>
  <nav id="navbar" className="navbar">
    <ul>
      <li>
        <a className="nav-a scrollto active" href="#hero">
        Home
        </a>
      </li>
      <li>
        <a className="nav-a scrollto" href="#about">
        About us
        </a>
      </li>
      <li>
        <a className="nav-a scrollto" href="#services">
        Services
        </a>
      </li>
      <li>
        <a className="getstarted scrollto" href="#footer">
        Get in touch
        </a>
      </li>
    </ul>
    <i className="bi bi-list mobile-nav-toggle"></i>
  </nav>
</>
);
};

export default Nav;