import React from "react";
import Clients from "../components/clients";
import Services from "../components/services";
import Footer from "../components/footer";
import aboutImage from "../assets/img/about.png";

const Home = () => {
  return(
    <>

    <section id="hero" className="d-flex align-items-center">
      <div
        className="container text-center position-relative"
        data-aos="fade-in"
        data-aos-delay="200"
      >
        <h1>LL Concepts </h1>
        <div className="row justify-content-center">
          <div className="col-md-8 col-12">
            <h2>
              a results-driven marketing communication one stop shop offering
              tailored services to our portfolio of clients across various
              industries.
            </h2>
          </div>
        </div>
        <a href="#about" className="btn-get-started scrollto">
          About us
        </a>
      </div>
    </section>
    <main id="main">
      <section id="about" className="about mt-5">
        <div className="container">
          <div className="row content">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <h2>About us</h2>
              <p>
                LL Concepts is a results-driven marketing communication one stop
                shop offering tailored services to our portfolio of clients
                across various industries. We specialise in supporting our
                clients with curated events and customized advertising, PR,
                marketing and digital campaigns across multi-channeled platforms
                through seamless integration of traditional and digital
                platforms complete with social media marketing.
              </p>
              <p>
                Our unique selling proposition is our ability to develop
                customised unique, fit for purpose concepts aimed at reaching
                targeted audiences without breaking the bank. Whether your goal
                is brand building or reputation management, LL Concepts will
                make it happen.
              </p>
              <p>
                We are a South African, 100% black-owned female led entity and a
                level 1 B-BBEEE contributor.
              </p>
            </div>
            <div
              className="col-lg-6 pt-4 pt-lg-0"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <img src={aboutImage} className="w-100" />
            </div>
          </div>
        </div>
      </section>
      <section id="why-us" className="why-us section-bg mt-5 py-5">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 d-flex align-items-stretch"
              data-aos="fade-right"
            >
              <div className="content">
                <h3>Why Choose LL Concepts?</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Duis aute irure dolor in reprehenderit Asperiores dolores sed
                  et. Tenetur quia eos. Autem tempore quibusdam vel
                  necessitatibus optio ad corporis.
                </p>
              </div>
            </div>
            <div className="col-lg-8 d-flex align-items-stretch">
              <div className="icon-boxes d-flex flex-column justify-content-center">
                <div className="row">
                  <div
                    className="col-xl-4 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-receipt"></i>
                      <h4>We are results driven – we deliver, period!</h4>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bxs-dashboard"></i>
                      <h4>
                        We understand your needs & provide tailored solutions
                      </h4>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-time-five"></i>
                      <h4>We understand the word “deadline” and we meet it</h4>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 d-flex align-items-stretch mt-lg-4"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-expand"></i>
                      <h4>We have vast industry knowledge and experience</h4>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 d-flex align-items-stretch mt-lg-4"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-user"></i>
                      <h4>We have an awesome team and we go the extra mile</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Services />
    </main>
    <Footer />
  </>
  )
};

export default Home;
