import React from "react";

const Services = () => {
  return (
    <>
      <section id="services" className="services mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="section-title" data-aos="fade-right">
                <h2>Services</h2>
                <p>
                  LL Concepts is a results-driven marketing communication one
                  stop shop offering tailored services to our portfolio of
                  clients across various industries.
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div
                  className="col-lg-10 pt-4 pt-lg-0"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <p>
                    Our comprehensive suite of services is designed to elevate
                    your brand to new heights. We specialize in ensuring that
                    every move is carefully planned to achieve maximum impact
                    and optimizing your reach and engagement through strategic
                    solutions. Our expertise brings your brand to life through
                    unforgettable experiences and ensures your brand's story is
                    heard loud and clear. 
                  </p>
                  <ul>
                    <li className="list-style-none">
                      <i className="ri-check-double-line"></i> Strategy
                      Development
                    </li>
                    <li className="list-style-none">
                      <i className="ri-check-double-line"></i> Advertising &
                      Media Buying Solutions
                    </li>
                    <li className="list-style-none">
                      <i className="ri-check-double-line"></i> Brand activations
                    </li>
                    <li className="list-style-none">
                      <i className="ri-check-double-line"></i> Events management
                    </li>
                    <li className="list-style-none">
                      <i className="ri-check-double-line"></i> PR, Publicity &
                      Media Relations
                    </li>
                    <li className="list-style-none">
                      <i className="ri-check-double-line"></i> Digital
                      Advertising
                    </li>
                    <li className="list-style-none">
                      <i className="ri-check-double-line"></i> Web and app
                      development and management
                    </li>
                    <li className="list-style-none">
                      <i className="ri-check-double-line"></i> Social Media
                      Management
                    </li>
                    <li className="list-style-none">
                      <i className="ri-check-double-line"></i> Online Branding
                      Solutions
                    </li>
                    <li className="list-style-none">
                      <i className="ri-check-double-line"></i> Publication
                      Design and Printing
                    </li>
                    <li className="list-style-none">
                      <i className="ri-check-double-line"></i> Content
                      Development and Creative Copy Solutions
                    </li>
                    <li className="list-style-none">
                      <i className="ri-check-double-line"></i> Video and
                      Animated Solutions
                    </li>
                    <li className="list-style-none">
                      <i className="ri-check-double-line"></i> Branding and
                      Promotional material
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
